export const People = [
    {
      name: "Vinsmoke Sanji",
      email: "vsanji@gmail.com",
      age: 21,
      image:
        "https://i.pinimg.com/564x/f4/5f/f5/f45ff54ede674f89580b33617015b6c8.jpg"
    },
    {
      name: "Roronoa Zoro",
      email: "rzoro@gmail.com",
      age: 22,
      image:
        "https://i.pinimg.com/564x/fc/d8/0c/fcd80c12ea7d13936c625f93cce57044.jpg"
    },
    {
      name: "Monkey D. luffy",
      email: "mluffy@gmail.com",
      age: 19,
      image:
        "https://i.pinimg.com/564x/45/4e/a4/454ea4e4969afa2093c1f1de9ed278b9.jpg"
    }
  ];