import React from 'react'

const Banner = () => {
  return (
    <div className='w-full mt-16'>
      <h1 className='font-bold text-9xl'>RENCONTRES TES SUPPORTERS</h1>
      <p className='mt-5'><strong>17 évènements</strong> - 1375 supporters sur OB - JO 2024 <strong>Marseille</strong></p>
    </div>
  )
}

export default Banner
